/* Header.css */

.container__slider {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: black;
}
  
.slider__item {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 1s ease-in-out;
    opacity: 0;
}
  
.slider__item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
}
  
.slider__item.active {
    opacity: 1;
}
  
.slider__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}
  
.slider__btn-next {
    right: 10px;
}
  
.slider__btn-prev {
    left: 10px;
}
  
.container__slider__links {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}
  
.dot {
    width: 4px;
    height: 13px;
    border-radius: 80%;
    background: white;
    border: 1px solid grey;
    transition: background-color 0.5s ease;
    cursor: pointer;
}
  
.dot.active {
    background: #D97A07;
    border: 1px solid #D97A07;
}
  
/* Text overlay */
.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 36px;
    text-decoration: underline;
    text-decoration-color: #9b5704;
    font-family: 'Readex Pro';
    font-weight: bold;
    text-align: center;
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
}
  
.text-overlay.active {
    opacity: 1;
    transform: translate(-50%, -50%);
}
  
.overlay-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
/* Button in overlay */
.overlay-button {
    display: inline-block;
    margin-top: 50px;
    padding: 15px 25px;
    background-color: #D97A07;
    font-family: 'Readex Pro';
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
}
  
.overlay-button:hover {
    background-color: #9b5704;
    color: black;
    transform: scale(1.1); /* Make the button bigger on hover */
}
  
.text-overlay.active .overlay-button {
    opacity: 1;
    transform: translateY(0);
}
  
/* Responsive Adjustments */

@media screen and (max-width: 768px) {
    .slider__btn,
    .container__slider__links {
        display: none;
    }

    .text-overlay {
        font-size: 26px; /* Adjust font size for smaller screens */
    }

    .overlay-button {
        font-size: 16px; /* Adjust button font size for smaller screens */
    }
}

@media screen and (max-width: 576px) {
    .text-overlay {
        font-size: 18px; 
       /* Further adjust font size for very small screens */
    }

    .overlay-button {
        font-size: 14px; /* Further adjust button font size for very small screens */
    }
}
@media screen and (max-width: 425px) {
    .text-overlay {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .overlay-button {
        font-size: 16px; /* Adjust button font size for smaller screens */
    }
}
@media screen and (max-width: 378px) {
    .text-overlay {
        font-size: 12px; /* Adjust font size for smaller screens */
    }

    .overlay-button {
        font-size: 16px; /* Adjust button font size for smaller screens */
    }
}
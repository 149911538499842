.container__slider1 {
    position: relative;
    width: 100%;
    height: 60vh; /* Adjust height as needed for responsiveness */
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: black;
  }
  
  .slider__item1 {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 1s ease-in-out;
    opacity: 0;
  }
  
  .slider__item1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slider__item1.active {
    opacity: 1;
  }
  
  /* Adding top and bottom opacity gradient effect */
  .slider__item1::before,
  .slider__item1::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 30%;
    pointer-events: none;
  }
  
  .slider__item1::before {
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 34, 63, 0.7), transparent);
  }
  
  .slider__item1::after {
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgba(0, 34, 63, 0.7), transparent);
  }
  
  .slider__btn1 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  .slider__btn-next1 {
    right: 10px;
  }
  
  .slider__btn-prev1 {
    left: 10px;
  }
  
  .container__slider__links1 {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .dot1 {
    width: 4px;
    height: 13px;
    border-radius: 80%;
    background: white;
    border: 1px solid grey;
    transition: background-color 0.5s ease;
    cursor: pointer;
  }
  
  .dot1.active {
    background: #D97A07;
    border: 1px solid #D97A07;
  }
  
  /* Text overlay */
  .overlay-content1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    color: white;
    z-index: 2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    left: 10%;
    top: 10%; /* Adjust top position for different screen sizes */
  }
  
  /* Button in overlay */
  .overlay-content1 button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #ff6900;
    color: white;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .overlay-content1 button:hover {
    background-color: #e05800;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 1200px) {
    .container__slider1 {
      height: 50vh; /* Adjust height for smaller screens */
    }
  
    .dot1 {
      width: 3px;
      height: 10px;
    }
  }
  
  @media (max-width: 992px) {
    .container__slider1 {
      height: 40vh; /* Further adjust height for smaller screens */
    }
  
    .overlay-content1 {
      font-size: 14px; /* Adjust font size for overlay content */
      left: 5%; /* Adjust position of overlay content */
      top: 5%; /* Adjust top position of overlay content */
    }
  
    .dot1 {
      width: 2px;
      height: 8px;
    }
  }
  
  @media (max-width: 768px) {
    .container__slider1 {
      height: 30vh; /* Adjust height for mobile screens */
    }
  
    .overlay-content1 {
      font-size: 12px; /* Further adjust font size for overlay content */
      left: 2%; /* Further adjust position of overlay content */
      top: 2%; /* Further adjust top position of overlay content */
    }
  
    .dot1 {
      width: 2px;
      height: 9px;
    }
  }
  
  @media (max-width: 576px) {
    .container__slider1 {
      height: 25vh; /* Adjust height for smaller mobile screens */
    }
  
    .overlay-content1 {
      font-size: 10px; /* Adjust font size for smaller screens */
      left: 0; /* Adjust position of overlay content */
      top: 0; /* Adjust top position of overlay content */
    }
  
    .dot1 {
     display: none;
    }
  }
  